import { Edit, Check } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, React } from "react";
import hasPerms from "../../../utils/hasPerms";
import CreateTitleModal from "./CreateTitleModal";
import EditTitleModal from "./EditTitleModal";
import api from "../../../utils/api";

export default function IAMTitlesTable({
  user,
  users,
  titles,
  setTitles,
  departments,
  showCreate,
  setShowCreate,
  parentReRender,
  setLoading,
}) {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(null);

  const handleOpen = (row) => {
    let titleCopy = { ...titles.find((title) => title.id === row.id) };
    setSelectedTitle(titleCopy);
    setShowEdit(true);
  };

  const saveTitle = async (title, type = "post") => {
    setShowCreate(false);
    setShowEdit(false);
    setLoading(true);
    try {
      let titleUpdateData = {};

      titleUpdateData.name = title.name;
      titleUpdateData.description = title.description;
      titleUpdateData.requiredLevel = title.requiredLevel;
      titleUpdateData.department = title.department.id;

      let res = null;

      if (type === "put") {
        res = await api.put("/title/" + title.id, titleUpdateData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
      } else if (type === "post") {
        titleUpdateData.department = title.department;
        titleUpdateData.validator = title.validator;

        res = await api.post("/title/", titleUpdateData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
      }
      if (!res) throw new Error("No response from server");

      let _titles = [...titles].filter((t) => t.id !== title.id);
      _titles.push(res.data);

      setTitles([..._titles]);
      setSelectedTitle(null);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    parentReRender();
  };

  const handleValidate = async (row) => {
    await api.put(
      `/title/${row.id}/validate`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    parentReRender();
  };

  let columns = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 50,
      valueGetter: (value, row) => `${row.id}`,
    },
    {
      field: "name",
      headerName: "Cargo",
      width: 225,
      valueGetter: (value, row) => `${row.name}`,
    },
    {
      field: "description",
      headerName: "Descripcion",
      width: 350,
      valueGetter: (value, row) => `${row.description}`,
    },
    {
      field: "requiredLevel",
      headerName: "Formacion Requerida",
      width: 200,
      valueGetter: (value, row) => {
        switch (row.requiredLevel) {
          case 1:
            return "Sin Estudios Requeridos";
          case 2:
            return "Educacion Basica Completa";
          case 3:
            return "Educacion Media Completa";
          case 4:
            return "Educacion Superior Completa";
          case 5:
            return "Postgrado Completo";
          case 6:
            return "Doctorado Completo";
          default:
            return "Sin Estudios Requeridos";
        }
      },
    },
    {
      field: "isValidated",
      headerName: "Status",
      width: 90,
      valueGetter: (value) => (value ? "Valido" : "Pendiente"),
    },
    {
      field: "edit-action",
      type: "actions",
      headerName: "",
      width: 50,
      cellClassName: "actions",
      getActions: (id) => {
        return [
          hasPerms(user, "IAM.Cargo.Editar") && (
            <Edit onClick={() => handleOpen(id)} />
          ),
        ].filter(Boolean); // Filter out undefined values
      },
    },
    {
      field: "validate-action",
      type: "actions",
      headerName: "",
      width: 50,
      cellClassName: "actions",
      getActions: (id) => {
        return [
          !id.row.isValidated &&
            hasPerms(user, "IAM.Cargo.Validar") &&
            id.row.validator?.rut.toString() === user.rut.toString() && (
              <Check onClick={() => handleValidate(id)} />
            ),
        ].filter(Boolean); // Filter out undefined values
      },
    },
  ];

  return (
    <>
      <DataGrid
        rows={titles}
        columns={columns}
        getRowId={(row) => row.id}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize:
                titles.length === 0
                  ? 20
                  : titles.length <= 20
                  ? titles.length
                  : 20,
            },
          },
        }}
        disableColumnResize
        pageSizeOptions={[titles.length, 10, 20, 50]}
        density="compact"
      />
      <EditTitleModal
        title={selectedTitle}
        openState={showEdit}
        handleCancel={() => setShowEdit(false)}
        handleSave={saveTitle}
        titles={titles}
        departments={departments}
      />
      <CreateTitleModal
        openState={showCreate}
        handleCancel={() => setShowCreate(false)}
        handleSave={saveTitle}
        titles={titles}
        departments={departments}
        users={users}
      />
    </>
  );
}
