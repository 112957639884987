const hasPermsStr = (user, permstr) => {
  permstr = permstr.split(".");
  return hasPerms(user, permstr[0], permstr[1], permstr[2]);
};

export default function hasPerms(user, system, mod = "_", action = "_") {
  if (!user?.permissions) {
    return false;
  }

  if (mod === "_" && action === "_") {
    return hasPermsStr(user, system);
  }

  let perm;
  try {
    perm = user.permissions
      .find((p) => p.system === system)
      .modules[mod].some((perm) => perm.tag === action);
  } catch {
    return false;
  }
  return perm === true || perm === false ? perm : false;
}
