import * as React from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Autocomplete from "@mui/material/Autocomplete";

export default function CreateDepartmentModal({
  openState,
  handleCancel,
  handleSave,
  users,
}) {
  const [department, setDepartment] = React.useState({
    name: "",
    description: "",
    manager: 0,
  });

  if (!openState) {
    return null;
  }

  return (
    <Modal
      open={openState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "background.paper",
          border: "0.1px solid #FFFFFF4B",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Grid container spacing={2} columns={12}>
          <Grid size={{ xs: 12, lg: 12 }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              size={{ xs: 12 }}
            >
              Creando nuevo Cargo
            </Typography>
          </Grid>
          {/* LINE 0 */}
          <Grid size={{ xs: 8, lg: 8 }}>
            <TextField
              id="Nombre"
              fullWidth
              label="Nombre"
              variant="standard"
              value={department.name}
              onChange={(e) => {
                department.name = e.target.value;
                setDepartment({ ...department });
              }}
            />
          </Grid>
          {/* LINE 1 */}
          <Grid size={{ xs: 4, lg: 12 }}>
            <TextField
              id="description"
              fullWidth
              label="Descripcion"
              variant="standard"
              value={department.description}
              onChange={(e) => {
                department.description = e.target.value;
                setDepartment({ ...department });
              }}
            />
          </Grid>

          <Grid size={{ xs: 6, lg: 6 }}>
            <Autocomplete
              size="large"
              disablePortal
              value={((manager) => {
                if (!manager || manager === 0) return "";

                let option = users.find((user) => user.rut === manager);
                return `${option.firstNames.split(" ")[0]} ${
                  option.fatherLastName
                } | ${option.rut}-${option.dv}`;
              })(department.manager)}
              options={users.map(
                (option) =>
                  `${option.firstNames.split(" ")[0]} ${
                    option.fatherLastName
                  } | ${option.rut}-${option.dv}`
              )}
              onChange={(e, value) => {
                if (!value || value === "" || value === 0) {
                  department.manager = 0;
                  setDepartment({ ...department });
                  return;
                }

                let rut = value.split(" | ")[1].split("-")[0];
                department.manager = parseInt(rut);
                setDepartment({ ...department });
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Supervisor" />
              )}
            />
          </Grid>

          <Grid container justifyContent="flex-end" size={{ xs: 6, lg: 6 }}>
            <Button
              variant="text"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleSave(department, "post");
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
