import * as React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Autocomplete from "@mui/material/Autocomplete";
import formatRut from "../../../utils/formatRut";

export default function CreateUserModal({
  openState,
  handleCancel,
  handleSave,
  titles,
  departments,
  users,
}) {
  const [user, setUser] = React.useState({
    rut: "",
    dv: "",
    firstNames: "",
    fatherLastName: "",
    motherLastName: "",
    email: "",
    department: null,
    title: null,
    manager: null,
    isActive: true,
  });

  if (!openState) {
    return null;
  }

  return (
    <Modal
      open={openState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "background.paper",
          border: "0.1px solid #FFFFFF4B",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Grid container spacing={2} columns={12}>
          <Grid size={{ xs: 12, lg: 12 }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              size={{ xs: 12 }}
            >
              Creando nuevo usuario
            </Typography>
          </Grid>
          {/* LINE 0 */}
          <Grid size={{ xs: 8, lg: 8 }}>
            <TextField
              id="rut"
              fullWidth
              label="RUT"
              variant="standard"
              value={user.rut}
              onChange={(e) => {
                user.rut = formatRut(e.target.value, false);
                setUser({ ...user });
              }}
            />
          </Grid>
          <Grid size={{ xs: 2, lg: 2 }}>
            <TextField
              id="dv"
              label="DV"
              variant="standard"
              value={user.dv}
              onChange={(e) => {
                user.dv = e.target.value.replace(/[^0-9kK]/g, "")[1];
                setUser({ ...user });
              }}
            />
          </Grid>
          <Grid size={{ xs: 2, lg: 2 }}>
            <FormControlLabel
              label="Activo"
              control={
                <Checkbox
                  checked={user.isActive}
                  onChange={(e) => {
                    user.isActive = !user.isActive;
                    setUser({ ...user });
                  }}
                />
              }
            />
          </Grid>
          {/* LINE 1 */}
          <Grid size={{ xs: 4, lg: 12 }}>
            <TextField
              id="nombres"
              fullWidth
              label="Nombres"
              variant="standard"
              value={user.firstNames}
              onChange={(e) => {
                setUser((prev) => ({
                  ...prev,
                  firstNames: e.target.value,
                }));
              }}
            />
          </Grid>
          {/* LINE 2 */}
          <Grid size={{ xs: 6, lg: 6 }}>
            <TextField
              id="apaterno"
              fullWidth
              label="Apellido Paterno"
              variant="standard"
              value={user.fatherLastName}
              onChange={(e) => {
                user.fatherLastName = e.target.value;
                setUser({ ...user });
              }}
            />
          </Grid>
          <Grid size={{ xs: 6, lg: 6 }}>
            <TextField
              id="amateno"
              fullWidth
              label="Apellido Materno"
              variant="standard"
              value={user.motherLastName}
              onChange={(e) => {
                user.motherLastName = e.target.value;
                setUser({ ...user });
              }}
            />
          </Grid>
          {/* LINE 3 */}
          <Grid size={{ xs: 6, lg: 6 }}>
            <TextField
              id="email"
              fullWidth
              label="Email"
              variant="standard"
              value={user.email}
              onChange={(e) => {
                user.email = e.target.value;
                setUser({ ...user });
              }}
            />
          </Grid>
          {/* LINE 4 */}
          <Grid size={{ xs: 6, lg: 6 }}>
            <Autocomplete
              size="large"
              disablePortal
              options={departments.map((option) => option.name)}
              sx={{ width: 300 }}
              value={user.department?.name ?? ""}
              onChange={(e, value) => {
                user.department = departments.find(
                  (dep) => dep?.name === value
                );
                setUser({ ...user });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Departamento" />
              )}
            />
          </Grid>
          <Grid size={{ xs: 6, lg: 6 }}>
            <Autocomplete
              size="large"
              disablePortal
              options={titles.map((option) => option.name)}
              sx={{ width: 300 }}
              value={user.title?.name ?? ""}
              onChange={(e, value) => {
                user.title = titles.find((dep) => dep?.name === value);
                setUser({ ...user });
              }}
              renderInput={(params) => <TextField {...params} label="Cargo" />}
            />
          </Grid>
          {/* LINE 5 */}
          <Grid size={{ xs: 6, lg: 6 }}>
            <Autocomplete
              size="large"
              disablePortal
              value={
                user.manager
                  ? `${user.manager?.firstNames?.split(" ")[0]} ${
                      user.manager?.fatherLastName
                    } | ${user.manager.rut}-${user.manager.dv}`
                  : ""
              }
              options={users.map(
                (option) =>
                  `${option.firstNames.split(" ")[0]} ${
                    option.fatherLastName
                  } | ${option.rut}-${option.dv}`
              )}
              onChange={(e, value) => {
                if (!value) {
                  user.manager = null;
                  return;
                }
                let rut = value.split(" | ")[1].split("-")[0];
                user.manager = users.find(
                  (user) => user.rut.toString() === rut.toString()
                );
                setUser({ ...user });
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Supervisor" />
              )}
            />
          </Grid>

          <Grid container justifyContent="flex-end" size={{ xs: 12, lg: 12 }}>
            <Button
              variant="text"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleSave(user, "post");
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
