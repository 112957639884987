export default function formatRut(value, dv = true) {
  let val = value.replace(/[^0-9kK]/g, "").toUpperCase();
  let tokens = val.split("");
  let usableRep = -1;

  if (tokens.length > 8) tokens = tokens.slice(0, dv ? 9 : 8);

  switch (tokens.length + (dv ? 0 : 1)) {
    case 1:
      usableRep = "x";
      break;
    case 2:
      usableRep = "x-x";
      break;
    case 3:
      usableRep = "xx-x";
      break;
    case 4:
      usableRep = "xxx-x";
      break;
    case 5:
      usableRep = "x.xxx-x";
      break;
    case 6:
      usableRep = "xx.xxx-x";
      break;
    case 7:
      usableRep = "xxx.xxx-x";
      break;
    case 8:
      usableRep = "x.xxx.xxx-x";
      break;
    case 9:
      usableRep = "xx.xxx.xxx-x";
      break;
    case 0:
      usableRep = "";
      break;
    default:
      usableRep = "";
      break;
  }

  if (!dv) usableRep = usableRep.replace("-x", "");

  for (let i = 0; i < tokens.length; i++)
    usableRep = usableRep.replace("x", tokens[i]);

  return usableRep;
}
