import * as React from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Autocomplete from "@mui/material/Autocomplete";

export default function EditTitleModal({
  title,
  openState,
  handleCancel,
  handleSave,
  departments,
}) {
  const [titleTmp, setTitleTmp] = React.useState(title);

  React.useEffect(() => {
    setTitleTmp(title);
  }, [title, setTitleTmp]);

  const handleTmp = (title) => {
    setTitleTmp({ ...title });
  };

  if (!title || !titleTmp) return null;

  const reqLevelTitles = [
    "Sin Estudios Requeridos",
    "Educacion Basica Completa",
    "Educacion Media Completa",
    "Educacion Superior Completa",
    "Postgrado Completo",
    "Doctorado Completo",
  ];

  return (
    <Modal
      open={openState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "background.paper",
          border: "0.1px solid #FFFFFF4B",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Grid container spacing={2} columns={12}>
          <Grid size={{ xs: 12, lg: 12 }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              size={{ xs: 12 }}
            >
              Editando cargo {`${title.id} - ${title.name}`}
            </Typography>
          </Grid>
          {/* LINE 0 */}
          <Grid size={{ xs: 8, lg: 8 }}>
            <TextField
              id="Nombre"
              fullWidth
              label="Nombre"
              variant="standard"
              value={title.name}
              onChange={(e) => {
                title.name = e.target.value;
                handleTmp({ ...title });
              }}
            />
          </Grid>
          {/* LINE 1 */}
          <Grid size={{ xs: 4, lg: 12 }}>
            <TextField
              id="description"
              fullWidth
              label="Descripcion"
              variant="standard"
              value={title.description}
              onChange={(e) => {
                title.description = e.target.value;
                handleTmp({ ...title });
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          {/* LINE 2 */}
          <Grid size={{ xs: 6, lg: 6 }}>
            <Autocomplete
              size="large"
              disablePortal
              options={reqLevelTitles.map((option) => option)}
              sx={{ width: 300 }}
              value={reqLevelTitles[title.requiredLevel]}
              onChange={(e, value) => {
                title.requiredLevel = reqLevelTitles.indexOf(value);
                handleTmp({ ...title });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Nivel Requerido" />
              )}
            />
          </Grid>
          <Grid size={{ xs: 6, lg: 6 }}>
            <Autocomplete
              size="large"
              disablePortal
              options={departments.map((option) => option.name)}
              sx={{ width: 300 }}
              value={title.department?.name ?? ""}
              onChange={(e, value) => {
                title.department = departments.find(
                  (dep) => dep?.name === value
                );
                handleTmp({ ...title });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Departamento" />
              )}
            />
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          {/* LINE 3 */}
          <Grid container justifyContent="flex-end" size={{ xs: 12, lg: 12 }}>
            <Button variant="text" onClick={() => handleCancel()}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSave(title, "put")}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
