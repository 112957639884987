import React from "react";
import {
  Modal,
  Box,
  Button,
  Tabs,
  Tab,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ModalOverflow from "@mui/joy/ModalOverflow";
import hasPerms from "../../../utils/hasPerms";

function CustomTabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const buildPerms = (system, user) => ({
  ...system,
  modules: Object.fromEntries(
    Object.entries(system.modules).map(([moduleKey, permissions]) => [
      moduleKey,
      permissions.map((permission) => ({
        ...permission,
        status: hasPerms(
          user,
          `${system.system}.${moduleKey}.${permission.tag}`
        ),
      })),
    ])
  ),
});

export default function EditPermissionsModal({
  user,
  handleCancel,
  allperms,
  openState,
  handleSave,
}) {
  const [value, setValue] = React.useState(0);
  const [permsObj, setPermsObj] = React.useState({});

  const handleTabSelect = (event, newValue) => setValue(newValue);

  React.useEffect(() => {
    let _permsObj = allperms.map((system) => buildPerms(system, user));

    setPermsObj(_permsObj);
    setValue(0);
  }, [user, allperms]);

  if (!user) return null;

  const updatePermission = (system, mod, action, value) => {
    let _permsObj = [...permsObj];
    let systemIndex = _permsObj.findIndex((perm) => perm.system === system);
    let moduleIndex = _permsObj[systemIndex].modules[mod].findIndex(
      (perm) => perm.tag === action
    );

    _permsObj[systemIndex].modules[mod][moduleIndex].status = value;
    setPermsObj(_permsObj);
  };

  const renderTabs = () =>
    allperms.map((perm, index) => (
      <Tab key={perm.system} label={perm.system} />
    ));

  const renderTabPanels = () =>
    allperms.map((perm, index) => (
      <CustomTabPanel key={perm.system} value={value} index={index}>
        <Grid container spacing={3}>
          {Object.keys(perm.modules).map((mod) => (
            <Grid container key={mod} spacing={0}>
              <Grid item size={12}>
                <strong>{mod}</strong>
              </Grid>
              {perm.modules[mod].map((action) => (
                <Grid item xs={12} key={action.tag}>
                  <FormControlLabel
                    label={action.tag}
                    control={
                      <Checkbox
                        checked={(() => {
                          let system = permsObj.find(
                            (p) => p.system === perm.system
                          );
                          if (!system) return false;

                          let mods = system.modules[mod];
                          if (!mods) return false;

                          return (
                            mods.find((a) => a.tag === action.tag).status ??
                            false
                          );
                        })()}
                        onChange={(e) =>
                          updatePermission(
                            perm.system,
                            mod,
                            action.tag,
                            e.target.checked
                          )
                        }
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </CustomTabPanel>
    ));

  return (
    <Modal open={openState}>
      <ModalOverflow>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            height: "80%",
            bgcolor: "background.paper",
            border: "0.1px solid #FFFFFF4B",
            borderRadius: 1,
            boxShadow: 24,
            p: 3,
            overflow: "scroll",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleTabSelect}>
              {renderTabs()}
            </Tabs>
          </Box>
          {renderTabPanels()}
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button variant="text" onClick={handleCancel}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => handleSave(user, permsObj)}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </ModalOverflow>
    </Modal>
  );
}
