import * as React from "react";
import { useEffect, useState } from "react";
import { alpha } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AppNavbar from "../components/AppNavbar";
import Header from "../components/Header";
import SideMenu from "../components/SideMenu";
import AppTheme from "../theme/AppTheme";
import {
  chartsCustomizations,
  dataGridCustomizations,
  treeViewCustomizations,
} from "../theme/customizations";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import IAMUsersTable from "../modules/iam/components/IAMUsersTable";
import IAMUserThree from "../modules/iam/components/IAMUserThree";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import hasPerms from "../utils/hasPerms";
import api from "../utils/api";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...treeViewCustomizations,
};

export default function HomeTemplate(props) {
  localStorage.setItem("service", "intranet");

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // For the loading state
  const [error, setError] = useState(null); // For error handling
  const [user, setUser] = useState(null);
  const [search, setSearch] = useState("");
  const [showCreateUser, setShowCreateUser] = useState(false);

  const fetchUsers = React.useCallback(async () => {
    try {
      const res = await api.get("/user/me", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      if (!res.data) throw new Error("No data");
      setUser(res.data);

      const users = await api.get("/user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      if (!users.data) throw new Error("No data");

      setData(users.data);
    } catch (error) {
      setError(error);
      navigate("/");
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const refetchUsers = async (reFetch = false) => fetchUsers();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return navigate("/");
  }

  let fuzzyUserSearch = (users, searchQuery) => {
    if (searchQuery === "") return users;
    let scored = [];
    let tokens = searchQuery.split(" ");

    users.forEach((user) => {
      let score = 0;

      tokens.forEach((token) => {
        if (user.firstNames.toUpperCase().includes(token.toUpperCase()))
          score += 3;
        if (user.fatherLastName.toUpperCase().includes(token.toUpperCase()))
          score += 3;
        if (user.email.toUpperCase().includes(token.toUpperCase())) score += 2;
        if (user.motherLastName.toUpperCase().includes(token.toUpperCase()))
          score += 2;
        if (user.rut.toString().toUpperCase().includes(token.toUpperCase()))
          score += 1.5;
        if (user.department.name.toUpperCase().includes(token.toUpperCase()))
          score += 1;
        if (user.title.name.toUpperCase().includes(token.toUpperCase()))
          score += 1;
        if (
          user.department.description
            .toUpperCase()
            .includes(token.toUpperCase())
        )
          score += 0.5;
        if (user.title.description.toUpperCase().includes(token.toUpperCase()))
          score += 0.5;
      });

      user["score"] = score;
      scored.push(user);
    });

    return scored.sort((a, b) => {
      return b.score - a.score;
    });
  };

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: "flex" }}>
        <SideMenu
          name={user.firstNames.split(" ")[0] + " " + user.fatherLastName}
          email={user.email}
          allowedPaths={user.permissions.map((x) => x.system)}
        />
        <AppNavbar />

        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: "auto",
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: "center",
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
              <Grid container spacing={2} columns={12}>
                <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
                  Usuarios IAM
                </Typography>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={85}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormControl
                    sx={{ width: { xs: "100%", md: "25ch" } }}
                    variant="outlined"
                  >
                    <OutlinedInput
                      size="small"
                      id="search"
                      placeholder="Buscar en Usuarios…"
                      onKeyUp={(e) => {
                        setSearch(e.target.value);
                      }}
                      sx={{ flexGrow: 1 }}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          sx={{ color: "text.primary" }}
                        >
                          <SearchRoundedIcon fontSize="small" />
                        </InputAdornment>
                      }
                      inputProps={{
                        "aria-label": "search",
                      }}
                    />
                  </FormControl>
                  {hasPerms(user, "IAM.Usuario.Crear") && (
                    <FormControl
                      sx={{ width: { xs: "100%", md: "25ch" } }}
                      variant="outlined"
                    >
                      <Button
                        variant="contained"
                        startIcon={<AddIcon />} // Add the plus icon
                        onClick={() => {
                          setShowCreateUser(true);
                        }}
                      >
                        Crear Usuario
                      </Button>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2} columns={12}>
                <Grid size={{ xs: 12, lg: 9 }}>
                  <IAMUsersTable
                    users={fuzzyUserSearch(data, search)}
                    showCreateUser={showCreateUser}
                    setShowCreateUser={setShowCreateUser}
                    parentReRender={(reFetch = false) => {
                      refetchUsers(reFetch);
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, lg: 3 }}>
                  <Stack
                    gap={2}
                    direction={{ xs: "column", sm: "row", lg: "column" }}
                  >
                    <IAMUserThree users={data} />
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
