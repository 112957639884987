import * as React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Autocomplete from "@mui/material/Autocomplete";

export default function EditUserModal({
  user,
  openState,
  handleCancel,
  handleSave,
  titles,
  departments,
  users,
}) {
  const [userTmp, setUserTmp] = React.useState(user);

  React.useEffect(() => {
    setUserTmp(user);
  }, [user, setUserTmp]);

  const handleTmp = (user) => {
    setUserTmp({ ...user });
  };

  if (!user || !userTmp) return null;

  return (
    <Modal
      open={openState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "background.paper",
          border: "0.1px solid #FFFFFF4B",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Grid container spacing={2} columns={12}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            size={{ xs: 12 }}
          >
            Editando {userTmp.firstNames} {userTmp.fatherLastName}{" "}
            {userTmp.motherLastName} | {userTmp.rut}-{userTmp.dv}
          </Typography>
          {/* LINE 0 */}
          <Grid size={{ xs: 8, lg: 8 }}>
            <TextField
              id="rut"
              fullWidth
              label="RUT"
              variant="standard"
              disabled
              value={userTmp.rut}
            />
          </Grid>
          <Grid size={{ xs: 2, lg: 2 }}>
            <TextField
              id="dv"
              label="DV"
              variant="standard"
              value={userTmp.dv}
            />
          </Grid>
          <Grid size={{ xs: 2, lg: 2 }}>
            <FormControlLabel
              label="Activo"
              control={
                <Checkbox
                  checked={userTmp.isActive}
                  onChange={(e) => {
                    userTmp.isActive = !userTmp.isActive;
                    handleTmp({ ...userTmp });
                  }}
                />
              }
            />
          </Grid>
          {/* LINE 1 */}
          <Grid size={{ xs: 4, lg: 12 }}>
            <TextField
              id="nombres"
              fullWidth
              label="Nombres"
              variant="standard"
              value={userTmp.firstNames}
              onChange={(e) => {
                handleTmp((prev) => ({
                  ...prev,
                  firstNames: e.target.value,
                }));
              }}
            />
          </Grid>
          {/* LINE 2 */}
          <Grid size={{ xs: 6, lg: 6 }}>
            <TextField
              id="apaterno"
              fullWidth
              label="Apellido Paterno"
              variant="standard"
              value={userTmp.fatherLastName}
              onChange={(e) => {
                userTmp.fatherLastName = e.target.value;
                handleTmp({ ...userTmp });
              }}
            />
          </Grid>
          <Grid size={{ xs: 6, lg: 6 }}>
            <TextField
              id="amateno"
              fullWidth
              label="Apellido Materno"
              variant="standard"
              value={userTmp.motherLastName}
              onChange={(e) => {
                userTmp.motherLastName = e.target.value;
                handleTmp({ ...userTmp });
              }}
            />
          </Grid>
          {/* LINE 3 */}
          <Grid size={{ xs: 6, lg: 6 }}>
            <TextField
              id="email"
              fullWidth
              label="Email"
              variant="standard"
              value={userTmp.email}
              onChange={(e) => {
                userTmp.email = e.target.value;
                handleTmp({ ...userTmp });
              }}
            />
          </Grid>
          {/* LINE 4 */}
          <Grid size={{ xs: 6, lg: 6 }}>
            <Autocomplete
              size="large"
              disablePortal
              options={departments.map((option) => option.name)}
              sx={{ width: 300 }}
              value={userTmp.department?.name ?? ""}
              onChange={(e, value) => {
                userTmp.department = departments.find(
                  (dep) => dep?.name === value
                );
                handleTmp({ ...userTmp });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Departamento" />
              )}
            />
          </Grid>
          <Grid size={{ xs: 6, lg: 6 }}>
            <Autocomplete
              size="large"
              disablePortal
              options={titles.map((option) => option.name)}
              sx={{ width: 300 }}
              value={userTmp.title?.name ?? ""}
              onChange={(e, value) => {
                userTmp.title = titles.find((dep) => dep?.name === value);
                handleTmp({ ...userTmp });
              }}
              renderInput={(params) => <TextField {...params} label="Cargo" />}
            />
          </Grid>
          {/* LINE 5 */}
          <Grid size={{ xs: 6, lg: 6 }}>
            <Autocomplete
              size="large"
              disablePortal
              value={
                userTmp.manager
                  ? `${userTmp.manager?.firstNames?.split(" ")[0]} ${
                      userTmp.manager?.fatherLastName
                    } | ${userTmp.manager.rut}-${userTmp.manager.dv}`
                  : ""
              }
              options={users.map(
                (option) =>
                  `${option.firstNames.split(" ")[0]} ${
                    option.fatherLastName
                  } | ${option.rut}-${option.dv}`
              )}
              onChange={(e, value) => {
                if (!value) {
                  userTmp.manager = null;
                  return;
                }
                let rut = value.split(" | ")[1].split("-")[0];
                userTmp.manager = users.find(
                  (userTmp) => userTmp.rut.toString() === rut.toString()
                );
                handleTmp({ ...userTmp });
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Supervisor" />
              )}
            />
          </Grid>

          <Grid container justifyContent="flex-end" size={{ xs: 12, lg: 12 }}>
            <Button
              variant="text"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleSave(userTmp, "put");
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
