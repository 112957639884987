import * as React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Breadcrumbs, { breadcrumbsClasses } from "@mui/material/Breadcrumbs";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import { useLocation } from "react-router-dom";

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  [`& .${breadcrumbsClasses.separator}`]: {
    color: (theme.vars || theme).palette.action.disabled,
    margin: 1,
  },
  [`& .${breadcrumbsClasses.ol}`]: {
    alignItems: "center",
  },
}));

export default function NavbarBreadcrumbs() {
  const location = useLocation();

  let routes = ("Sauce" + location.pathname).split("/");
  let last = routes.pop();

  let children = routes.map((r, index) => {
    return (
      <Typography variant="body1" key={r}>
        {r}
      </Typography>
    );
  });

  children.push(
    <Typography
      key={last}
      variant="body1"
      sx={{ color: "text.primary", fontWeight: 600 }}
    >
      {last}
    </Typography>
  );

  return (
    <StyledBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextRoundedIcon fontSize="small" />}
    >
      {children}
    </StyledBreadcrumbs>
  );
}
