import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from "./pages/NoPage";
import Login from "./pages/Login";
import ErrorPage from "./pages/ErrorPage";
import ForgotPassword from "./pages/ForgotPassword";
import HomeTemplate from "./pages/HomeTemplate";
import SedPage from "./pages/SedPage";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import IamPage from "./modules/iam/pages/IamPage";

export default function App() {
  localStorage.setItem("systemTabs", [
    { text: "Home", icon: <HomeRoundedIcon /> },
  ]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="login" element={<Login />}></Route>

        <Route path="IAM" element={<IamPage />}></Route>
        <Route path="IAM/:subSystem" element={<IamPage />}></Route>

        <Route path="SED" element={<SedPage />}></Route>
        <Route path="SED/:subSystem" element={<SedPage />}></Route>

        <Route path="INT" element={<HomeTemplate />}></Route>

        <Route path="forgot" element={<ForgotPassword />}></Route>
        <Route path="reset" element={<ForgotPassword />}></Route>
        <Route path="u/:username" element={<NoPage />}></Route>
        <Route path="*" element={<ErrorPage />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
