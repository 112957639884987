import React from "react";
import { Box, Button, Typography } from "@mui/material";
import AppTheme from "../theme/AppTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";
import { useNavigate, useLocation } from "react-router-dom";
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from "../theme/customizations";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function ErrorPage(props) {
  const navigate = useNavigate();
  const location = useLocation();

  let path = location.pathname.split("/");

  let type = parseInt(path.pop());
  type = type || 404;

  let contents = {
    100: {
      title: "100 - Continue",
      messageFirstLine:
        "The server has received the request headers and the client should proceed to send the request body.",
      messageSecondLine: "Please try again latter.",
    },
    101: {
      title: "101 - Switching Protocols",
      messageFirstLine:
        "The requester has asked the server to switch protocols and the server has agreed to do so.",
      messageSecondLine: "Please try again latter.",
    },
    102: {
      title: "102 - Processing",
      messageFirstLine:
        "The server has received and is processing the request, but no response is available yet.",
      messageSecondLine: "Please try again latter.",
    },
    103: {
      title: "103 - Early Hints",
      messageFirstLine:
        "Used to return some response headers before final HTTP message.",
      messageSecondLine: "Please try again latter.",
    },
    200: {
      title: "200 - OK",
      messageFirstLine: "The request has succeeded.",
      messageSecondLine: "Please try again latter.",
    },
    201: {
      title: "201 - Created",
      messageFirstLine:
        "The request has been fulfilled, resulting in the creation of a new resource.",
      messageSecondLine: "Please try again latter.",
    },
    202: {
      title: "202 - Accepted",
      messageFirstLine:
        "The request has been accepted for processing, but the processing has not been completed.",
      messageSecondLine: "Please try again latter.",
    },
    203: {
      title: "203 - Non-Authoritative Information",
      messageFirstLine:
        "The server successfully processed the request, but is returning information that may be from another source.",
      messageSecondLine: "Please try again latter.",
    },
    204: {
      title: "204 - No Content",
      messageFirstLine:
        "The server successfully processed the request, but is not returning any content.",
      messageSecondLine: "Please try again latter.",
    },
    205: {
      title: "205 - Reset Content",
      messageFirstLine:
        "The server successfully processed the request, but is not returning any content.",
      messageSecondLine: "Please try again latter.",
    },
    206: {
      title: "206 - Partial Content",
      messageFirstLine:
        "The server is delivering only part of the resource due to a range header sent by the client.",
      messageSecondLine: "Please try again latter.",
    },
    207: {
      title: "207 - Multi-Status",
      messageFirstLine:
        "The message body that follows is by default an XML message and can contain a number of separate response codes.",
      messageSecondLine: "Please try again latter.",
    },
    208: {
      title: "208 - Already Reported",
      messageFirstLine:
        "The members of a DAV binding have already been enumerated in a previous reply to this request, and are not being included again.",
      messageSecondLine: "Please try again latter.",
    },
    300: {
      title: "300 - Multiple Choices",
      messageFirstLine: "The request has more than one possible response.",
      messageSecondLine: "Please try again latter.",
    },
    301: {
      title: "301 - Moved Permanently",
      messageFirstLine:
        "The URL of the requested resource has been changed permanently.",
      messageSecondLine: "Please try again latter.",
    },
    302: {
      title: "302 - Found",
      messageFirstLine:
        "The URL of the requested resource has been changed temporarily.",
      messageSecondLine: "Please try again latter.",
    },
    303: {
      title: "303 - See Other",
      messageFirstLine:
        "The server sent this response to direct the client to get the requested resource at another URI with a GET request.",
      messageSecondLine: "Please try again latter.",
    },
    304: {
      title: "304 - Not Modified",
      messageFirstLine: "This is used for caching purposes.",
      messageSecondLine: "Please try again latter.",
    },
    305: {
      title: "305 - Use Proxy",
      messageFirstLine:
        "The requested resource is available only through a proxy, the address for which is provided in the response.",
      messageSecondLine: "Please try again latter.",
    },
    306: {
      title: "306 - Switch Proxy",
      messageFirstLine: "No longer used.",
      messageSecondLine: "Please try again latter.",
    },
    307: {
      title: "307 - Temporary Redirect",
      messageFirstLine:
        "The server sends this response to direct the client to get the requested resource at another URI with same method that was used in the prior request.",
      messageSecondLine: "Please try again latter.",
    },
    308: {
      title: "308 - Permanent Redirect",
      messageFirstLine:
        "This means that the resource is now permanently located at another URI, specified by the Location: HTTP Response header.",
      messageSecondLine: "Please try again latter.",
    },
    400: {
      title: "400 - Bad Request",
      messageFirstLine:
        "The server could not understand the request due to invalid syntax.",
      messageSecondLine: "Please try again latter.",
    },
    401: {
      title: "401 - Unauthorized",
      messageFirstLine:
        "The client must authenticate itself to get the requested response.",
      messageSecondLine: "Please try again latter.",
    },
    402: {
      title: "402 - Payment Required",
      messageFirstLine: "Reserved for future use.",
      messageSecondLine: "Please try again latter.",
    },
    403: {
      title: "403 - Forbidden",
      messageFirstLine:
        "The client does not have access rights to the content.",
      messageSecondLine: "Please try again latter.",
    },
    404: {
      title: "404 - Not Found",
      messageFirstLine: "The server can not find the requested resource.",
      messageSecondLine: "Please try again latter.",
    },
    405: {
      title: "405 - Method Not Allowed",
      messageFirstLine: "The method specified in the request is not allowed.",
      messageSecondLine: "Please try again latter.",
    },
    406: {
      title: "406 - Not Acceptable",
      messageFirstLine:
        "The server can only generate a response that is not accepted by the client.",
      messageSecondLine: "Please try again latter.",
    },
    407: {
      title: "407 - Proxy Authentication Required",
      messageFirstLine:
        "You must authenticate with a proxy server before this request can be served.",
      messageSecondLine: "Please try again latter.",
    },
    408: {
      title: "408 - Request Timeout",
      messageFirstLine:
        "The request took longer than the server was prepared to wait.",
      messageSecondLine: "Please try again latter.",
    },
    409: {
      title: "409 - Conflict",
      messageFirstLine:
        "The request could not be completed because of a conflict.",
      messageSecondLine: "Please try again latter.",
    },
    410: {
      title: "410 - Gone",
      messageFirstLine: "The requested page is no longer available.",
      messageSecondLine: "Please try again latter.",
    },
    411: {
      title: "411 - Length Required",
      messageFirstLine:
        "The 'Content-Length' is not defined. The server will not accept the request without it.",
      messageSecondLine: "Please try again latter.",
    },

    412: {
      title: "412 - Precondition Failed",
      messageFirstLine:
        "The precondition given in the request evaluated to false by the server.",
      messageSecondLine: "Please try again latter.",
    },
    413: {
      title: "413 - Payload Too Large",
      messageFirstLine:
        "The server will not accept the request, because the request entity is too large.",
      messageSecondLine: "Please try again latter.",
    },
    414: {
      title: "414 - URI Too Long",
      messageFirstLine:
        "The URI requested by the client is longer than the server is willing to interpret.",
      messageSecondLine: "Please try again latter.",
    },
    415: {
      title: "415 - Unsupported Media Type",
      messageFirstLine:
        "The media format of the requested data is not supported by the server.",
      messageSecondLine: "Please try again latter.",
    },
    416: {
      title: "416 - Range Not Satisfiable",
      messageFirstLine:
        "The range specified by the 'Range' header in the request can't be fulfilled by the server.",
      messageSecondLine: "Please try again latter.",
    },
    417: {
      title: "417 - Expectation Failed",
      messageFirstLine:
        "The server cannot meet the requirements of the Expect request-header field.",
      messageSecondLine: "Please try again latter.",
    },
    418: {
      title: "418 - I'm a teapot",
      messageFirstLine:
        "The server refuses the attempt to brew coffee with a teapot.",
      messageSecondLine: "Please try again latter.",
    },
    421: {
      title: "421 - Misdirected Request",
      messageFirstLine:
        "The request was directed at a server that is not able to produce a response.",
      messageSecondLine: "Please try again latter.",
    },
    422: {
      title: "422 - Unprocessable Entity",
      messageFirstLine:
        "The request was well-formed but was unable to be followed due to semantic errors.",
      messageSecondLine: "Please try again latter.",
    },
    423: {
      title: "423 - Locked",
      messageFirstLine: "The resource that is being accessed is locked.",
      messageSecondLine: "Please try again latter.",
    },
    424: {
      title: "424 - Failed Dependency",
      messageFirstLine:
        "The request failed due to failure of a previous request.",
      messageSecondLine: "Please try again latter.",
    },
    425: {
      title: "425 - Too Early",
      messageFirstLine:
        "Indicates that the server is unwilling to risk processing a request that might be replayed.",
      messageSecondLine: "Please try again latter.",
    },
    426: {
      title: "426 - Upgrade Required",
      messageFirstLine:
        "The client should switch to a different protocol such as TLS/1.0.",
      messageSecondLine: "Please try again latter.",
    },
    428: {
      title: "428 - Precondition Required",
      messageFirstLine:
        "The origin server requires the request to be conditional.",
      messageSecondLine: "Please try again latter.",
    },
    429: {
      title: "429 - Too Many Requests",
      messageFirstLine:
        "The user has sent too many requests in a given amount of time.",
      messageSecondLine: "Please try again latter.",
    },
    431: {
      title: "431 - Request Header Fields Too Large",
      messageFirstLine:
        "The server is unwilling to process the request because either an individual header field, or all the header fields collectively, are too large.",
      messageSecondLine: "Please try again latter.",
    },
    451: {
      title: "451 - Unavailable For Legal Reasons",
      messageFirstLine:
        "The user requests an illegal resource, such as a web page censored by a government.",
      messageSecondLine: "Please try again latter.",
    },
    500: {
      title: "500 - Internal Server Error",
      messageFirstLine:
        "The server has encountered a situation it doesn't know how to handle.",
      messageSecondLine: "Please try again latter.",
    },
    501: {
      title: "501 - Not Implemented",
      messageFirstLine:
        "The request method is not supported by the server and cannot be handled.",
      messageSecondLine: "Please try again latter.",
    },
    502: {
      title: "502 - Bad Gateway",
      messageFirstLine:
        "The server, while acting as a gateway or proxy, received an invalid response from an inbound server it accessed while attempting to fulfill the request.",
      messageSecondLine: "Please try again latter.",
    },
    503: {
      title: "503 - Service Unavailable",
      messageFirstLine: "The server is not ready to handle the request.",
      messageSecondLine: "Please try again latter.",
    },
    504: {
      title: "504 - Gateway Timeout",
      messageFirstLine:
        "The server, while acting as a gateway or proxy, did not receive a timely response from an upstream server it needed to access in order to complete the request.",
      messageSecondLine: "Please try again latter.",
    },
    505: {
      title: "505 - HTTP Version Not Supported",
      messageFirstLine:
        "The server does not support, or refuses to support, the major version of HTTP that was used in the request message.",
      messageSecondLine: "Please try again latter.",
    },
    511: {
      title: "511 - Network Authentication Required",
      messageFirstLine:
        "The client needs to authenticate to gain network access.",
      messageSecondLine: "Please try again latter.",
    },
  };

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Stack spacing={2}>
          {path.pop() === "cute" ? (
            <img
              alt={`the error was ${type}`}
              src={"https://http.cat/images/" + type + ".jpg"}
              width={"800px"}
            />
          ) : (
            <>
              <Typography variant="h1" component="h1">
                {contents[type].title}
              </Typography>

              <Typography variant="h4" component="h4">
                {contents[type].messageFirstLine}
                <br />
                {contents[type].messageSecondLine}
              </Typography>
            </>
          )}
          <Button
            onClick={() => {
              navigate("/login");
            }}
            variant="contained"
          >
            Back to Home
          </Button>
        </Stack>
      </Box>
    </AppTheme>
  );
}
