import { Edit } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, React } from "react";
import hasPerms from "../../../utils/hasPerms";
import EditDepartmentModal from "./EditDepartmentModal";
import CreateDepartmentModal from "./CreateDepartmentModal";
import api from "../../../utils/api";

export default function IAMDepartmentsTable({
  user,
  users,
  setUsers,
  titles,
  setTitles,
  departments,
  setDepartments,
  perms,
  setPerms,
  showCreate,
  setShowCreate,
  parentReRender,
  setLoading,
}) {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const handleOpen = (row) => {
    let departmentCopy = { ...departments.find((dept) => dept.id === row.id) };
    setSelectedDepartment(departmentCopy);
    setShowEdit(true);
  };

  const saveDepartment = async (dept, type = "post") => {
    setShowCreate(false);
    setShowEdit(false);
    setLoading(true);
    try {
      let updateData = {};

      updateData.name = dept.name;
      updateData.description = dept.description;
      updateData.requiredLevel = dept.requiredLevel;
      updateData.department = dept.id;

      let res = null;

      if (type === "put") {
        res = await api.put("/department/" + dept.id, updateData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
      } else if (type === "post") {
        res = await api.post("/department/", updateData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
      }

      if (!res) throw new Error("No response from server");

      let _departments = [...departments].filter((t) => t.id !== dept.id);
      _departments.push(res.data);

      setDepartments([..._departments]);
      setSelectedDepartment(null);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    parentReRender();
  };

  let columns = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 50,
      valueGetter: (value, row) => `${row.id}`,
    },
    {
      field: "name",
      headerName: "Nombre",
      width: 225,
      valueGetter: (value, row) => `${row.name}`,
    },
    {
      field: "description",
      headerName: "Descripcion",
      width: 350,
      valueGetter: (value, row) => `${row.description}`,
    },
    {
      field: "edit-action",
      type: "actions",
      headerName: "",
      width: 50,
      cellClassName: "actions",
      getActions: (id) => {
        return [
          hasPerms(user, "IAM.Cargo.Editar") && (
            <Edit onClick={() => handleOpen(id)} />
          ),
        ].filter(Boolean); // Filter out undefined values
      },
    },
  ];

  return (
    <>
      <DataGrid
        rows={departments}
        columns={columns}
        getRowId={(row) => row.id}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize:
                departments.length === 0
                  ? 20
                  : departments.length < 20
                  ? departments.length
                  : 20,
            },
          },
        }}
        disableColumnResize
        pageSizeOptions={[
          departments.length < 20 ? departments.length : null,
          10,
          20,
          50,
        ].filter((x) => x !== null)}
        density="compact"
      />
      <EditDepartmentModal
        department={selectedDepartment}
        openState={showEdit}
        handleCancel={() => setShowEdit(false)}
        handleSave={saveDepartment}
        users={users}
      />
      <CreateDepartmentModal
        openState={showCreate}
        handleCancel={() => setShowCreate(false)}
        handleSave={saveDepartment}
        users={users}
      />
    </>
  );
}
