import * as React from "react";
import { useEffect, useState } from "react";
import { alpha } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AppNavbar from "../components/AppNavbar";
import Header from "../components/Header";
import SideMenu from "../components/SideMenu";
import AppTheme from "../theme/AppTheme";
import {
  chartsCustomizations,
  dataGridCustomizations,
  treeViewCustomizations,
} from "../theme/customizations";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import { BadgeRounded, FactoryRounded } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import api from "../utils/api";
import { Container } from "@mui/material";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...treeViewCustomizations,
};

export default function SedPage() {
  const { subSystem } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  if (!subSystem) {
    navigate("/SED/Usuarios");
  }

  useEffect(() => {
    async function effect() {
      try {
        const res = await api.get("/user/me", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });

        setUser(res.data);
        setLoading(false);
      } catch (e) {
        navigate("/login");
      }
    }
    effect();
  }, [subSystem, navigate]);

  if (loading) {
    return (
      <AppTheme themeComponents={xThemeComponents}>
        <CssBaseline enableColorScheme />
        <Box sx={{ display: "flex" }}>
          <AppNavbar />
        </Box>
      </AppTheme>
    );
  }

  return (
    <AppTheme themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: "flex" }}>
        <SideMenu
          name={user.firstNames.split(" ")[0] + " " + user.fatherLastName}
          email={user.email}
          allowedPaths={user.permissions.map((x) => x.system)}
          menuItems={[
            { text: "Objetivos", to: "Objetivos", icon: <PeopleRoundedIcon /> },
            {
              text: "Periodos Evaluados",
              to: "PeriodosEvaluados",
              icon: <BadgeRounded />,
            },
            {
              text: "Tareas",
              to: "Tareas",
              icon: <FactoryRounded />,
            },
            {
              text: "Competencias",
              to: "Competencias",
              icon: <FactoryRounded />,
            },

            {
              text: "Comentarios",
              to: "Comentarios",
              icon: <FactoryRounded />,
            },
          ]}
          onClick={(to) => {
            navigate("/SED/" + to);
          }}
          selected={subSystem}
        />
        <AppNavbar />

        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: "auto",
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: "center",
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
              {((sys) => {
                return <Container user={user} />;
                //switch (sys) {
                //  case "Usuarios":
                //    return <IamUsersPage user={user} />;
                //  case "Cargos":
                //    return <IamTitlesPage user={user} />;
                //  case "Departamentos":
                //    return <IamDepartmentsPage user={user} />;
                //  default:
                //    return <Container user={user} />;
                //}
              })(subSystem)}
            </Box>
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
